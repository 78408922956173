var site = site || {};
var generic = generic || {};

(function ($) {
  window.site = site || {};
  site.offers = site.offers || {};
  site.offers.welcome15 = site.offers.welcome15 || {};
  var welcome_overlay_override = Drupal.settings.welcome_overlay ? 1 : 0;
  var welcome_overlay_loyalty = Drupal.settings.welcome_overlay_loyalty ? 1 : 0;

  // --------
  // Primary behavior responsible for initializing the welcome15 offer logic

  Drupal.behaviors.ELB_welcome15 = {
    // debug config
    debug: false, // when debugging, the overlay opens on all page loads
    showThanks: true, // even if there's an error, show the "thank you" message
    showSignedUp: false, // even if there's an error, show the "already signed up" message

    // storage cookie name
    offerCookie: 'welcome15',

    // # of seconds before the overlay appears
    defaultTimeout: 3000,

    // template path config
    templates: {
      form: 'welcome_15',
      // already_signed_up: 'welcome15_offer_signed_up', Not used, always show the success regardless of if signed up before or not
      thanks: 'welcome_15_success',
      error: 'welcome15_offer_error',
      disclaimer: 'welcome15_offer_disclaimer'
    },

    // overlay config
    overlay: {
      pc: {
        className: 'welcome15-overlay',
        transition: 'none',
        height: 'auto',
        width: 620,
        overlayClose: true,
        trapFocus: false
      },
      mobile: {
        className: 'welcome15-overlay',
        width: '90%',
        height: '90%',
        maxHeight: '90%',
        opacity: '0',
        transition: 'none',
        trapFocus: false
      }
    },

    isStr: function (str) {
      return typeof str === 'string';
    },

    isFunc: function (func) {
      return typeof func === 'function';
    },

    isObj: function (mixed_var) {
      if (Object.prototype.toString.call(mixed_var) === '[object Array]') {
        return false;
      }

      return mixed_var !== null && typeof mixed_var === 'object';
    },

    /**
     * Helper function to get the raw contents of an JS inline template, and optionally interpolate
     * the html using Mustache
     */
    getTemplateContent: function (key, data) {
      var undef;
      var path;
      var Mustache = Mustache || {};

      // Sanitize the incoming data
      path = key !== undef ? key : 'foobar_template';
      data = data || false;

      // Get the html content of the template
      var html = site.template.get({
        name: key,
        data: data
      });

      if (html.length === 0) {
        return $();
      }

      // If there's no data object passed in, then just return a basic jquery element
      if (data === false || !$.isFunction(Mustache.render)) {
        return $(html);
      }

      // We have html and data, which means we should use Mustache to render the output
      return $(Mustache.render(html, data));
    },

    // Open a colorbox window
    openWindow: function (content, callBacks) {
      var self = this;

      content = self.isObj(content) ? content : $();

      // Dumb trick to get the entire contents of the html out of the jquery object
      content = content.wrap('<div />').parent().html();

      if (!$.isFunction($.colorbox)) {
        return false;
      }
      if (welcome_overlay_override) {
        self.overlay.pc.width = 750;
      }

      var args = {
        html: content,
        height: 'auto',
        maxWidth: '100%',
        maxHeight: '100%',
        closeButton: true,
        scrolling: false,
        close: '<i class="icon icon--close"></i>'
      };
      var darg = $('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc;

      $.extend(args, darg);

      if (self.isObj(callBacks)) {
        for (var k in callBacks) {
          var func = callBacks[k];

          if (self.isFunc(func)) {
            args[k] = func;
          }
        }
      }

      $.colorbox(args);
    },

    // Show the signup form
    showSignupForm: function (delayTime, context) {
      var self = this;

      setTimeout(function () {
        var content = self.getTemplateContent(self.templates.form);
        var cboxArgs;
        // There is an input that creates a div that we can grab and specify an alternate layout class for i224733
        var useAltMarkUp = $(content).find('#USE_ALT_LAYOUT').length;

        if (content.hasClass('js-welcome-15-hide')) {
          return;
        }

        if (useAltMarkUp) {
          cboxArgs = $('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc;
          cboxArgs.className = 'welcome15-overlay alt_layout1';
          cboxArgs.fixed = true;
        }

        self.openWindow(content, {
          // Hide the content and overlay in onOpen, so we can fade it in initSignUpForm
          // This prevents "sliding" while the overridden css is loading, and also makes the overlay and content fade in simultaneously
          onOpen: function () {
            $('.welcome15-overlay #cboxContent,#cboxOverlay').css('visibility', 'hidden');
          },
          onComplete: function () {
            site.offers.welcome15.initSignUpForm(context);
            // This fixes a bug on the m. domain where the colorbox
            // would take up the full height on mobile devices.
            if ($('body').hasClass('device-mobile')) {
              $.colorbox.resize();
            }
          }
        });
      }, delayTime);
    },

    /**
     * Helper function to determine if the welcome15 offer should appear on this page
     * @TODO: this will likely be expanded to be disabled on checkout and whatnot
     */
    showOfferOnPage: function () {
      var self = this,
        cookieName = self.offerCookie,
        hasCookie = $.cookie(cookieName),
        showOffer = false;
      // adding to allow manual disabling of the popover
      var disabled = 0;
      var popupShowAtVisitCount = Drupal.settings.globals_variables.popup_show_at_visit_count || false;

      if (!hasCookie) {
        // there is a div in the mustache that gets printed based on the disabled checbox input welcome15_offer_signup_v1
        var markUp = self.getTemplateContent(self.templates.form);
        var markUpDisabled = $(markUp).find('#DISABLE_POPOVER');

        // set the session cookie if the page is disabled, rather than checking every time
        if (markUpDisabled.length > 0) {
          $.cookie(cookieName, '1', {
            expires: 14,
            path: '/'
          });

          return false;
        }
      }
      if (
        self.debug ||
        (!hasCookie && !Drupal.settings.common.show_welcome_every_session) ||
        (Drupal.settings.common.show_welcome_every_session && !sessionStorage.getItem('mx_newsletter_session'))
      ) {
        showOffer = true;
      }
      // 'RPC:RESULT' event used for getting cp.utag_main__pn value
      if (popupShowAtVisitCount) {
        showOffer = false;
        $(document).on('RPC:RESULT', function () {
          if (!showOffer && parseInt(site.trackingDataLayer.data['cp.utag_main__pn']) === popupShowAtVisitCount) {
            self.showSignupForm();
            showOffer = true;
          }
        });
      }

      return showOffer;
    },

    // Main function to get things kicked off
    attach: function (context, settings) {
      if (settings.hide_welcome15_overlay_onload) {
        return false;
      }
      var self = this,
        cookieName = self.offerCookie;

      // If the user hasn't seen the popup, then show it to them
      if (self.showOfferOnPage()) {
        this.showSignupForm(self.defaultTimeout, context);
      }

      // Create the cookie
      $.cookie(cookieName, '1', {
        expires: 14,
        path: '/'
      });
      // create the session
      if (Drupal.settings.common.show_welcome_every_session) {
        sessionStorage.setItem('mx_newsletter_session', 'true');
      }

      $(window).on('open:emailSignupOverlay', function () {
        self.showSignupForm(0, context);
      });

      // Bind to the email_signup.success event
      // When a user does the email sign up in the footer, we'll show them the welcome15 overlay
      // if the offer is still valid, and they haven't signed up already
      $(context).on('email_signup.success', function (event, rpcResponse) {
        var response = rpcResponse.getData(),
          userinfo = response.userinfo || {},
          templates = self.templates,
          content = '';
        var previously_opted_in_email_promotion = userinfo.previously_opted_in_email_promotion || 0;

        // commenting this out for now - might want to show already_signed_up again in the future
        // .. only show the overlay if the user hasn't already registered
        // if (previously_opted_in_email_promotion == 0) {
        content = templates.thanks;
        // } else {
        //  content = templates.already_signed_up;
        // }

        if (content.length > 0) {
          // @TODO: triggering an overlay will close the special-offers-tray. We don't want it to do that.
          var html = self.getTemplateContent(content);

          self.openWindow(html, {
            onComplete: function () {
              $.colorbox.resize();
            }
          });
        }
      });

      // Bind to the email_signup.error event for no particular reason except that it exists
      // Do we need to show an error message?
      $(context).on('email_signup.error', function (event, rpcResponse) {});

      // Bind to the cbox_complete event. This fires anytime a colorbox window is opened.
      // This event handler adds the disclaimer text to the overlays, but could be used for similiar tasks as well.
      $(context).on('cbox_complete', function () {
        var overlay = $('body').hasClass('device-mobile') ? self.overlay.mobile : self.overlay.pc;
        var $element = $('#cboxContent', '#colorbox.' + overlay.className.split(' ')[0]);

        // Make sure we're only applying this to the welcome15 overlay
        if ($element.length > 0) {
          $('.email_popover__disclaimer', $element)
            .find('a')
            .once('disclaimer-link')
            .click(function (e) {
              e.preventDefault();

              var $me = $(this),
                dClass = 'email_popover--disclaimer',
                $content = $me.closest('.email_popover'),
                speed = 0;
              var $disclaimerOverlay = $('.' + dClass, $content);

              // The disclaimer overlay layer doesn't exist, so let's create it
              if ($disclaimerOverlay.length === 0) {
                $disclaimerOverlay = $('<div></div>')
                  .addClass(dClass)
                  .html(self.getTemplateContent(self.templates.disclaimer));

                // ... add it to the content layer
                $disclaimerOverlay.prependTo($content);
              }

              // ... hide/show the overlay
              $disclaimerOverlay.toggle(speed, function () {
                var $this = $(this),
                  $cbox = $('#cboxContent'),
                  $cbClose = $('#cboxClose');
                var newCloseClass = 'cboxClose--alt',
                  closeButton = $('.trans-close', $this).text(),
                  $poContent = $('.email_popover__content'),
                  $poDisclaimer = $('.email_popover__disclaimer'),
                  $poSocial = $('.email_popover__social_icons'),
                  useAltMarkUp = $($content).find('#USE_ALT_LAYOUT').length;

                // "show" adds this overflow attribute automagically
                // we'll remove it, plus add the "scrollable" class
                $this.css('overflow', '').addClass('scrollable');

                // If the overlay is being shown, do stuff
                if ($this.is(':visible')) {
                  $cbClose.hide();

                  // when using the alt layout, hide the other elements
                  if (useAltMarkUp) {
                    $poContent.hide();
                    $poDisclaimer.hide();
                    $poSocial.hide();
                  }

                  // Create a new close button, and add it to the overlay
                  var $newCloseButton = $('<div />').attr('id', newCloseClass).html(closeButton);

                  $newCloseButton.click(function () {
                    $disclaimerOverlay.add($(this)).remove();
                    $cbClose.show();

                    // when using the alt layout, show the other elements
                    if (useAltMarkUp) {
                      $poContent.show();
                      $poDisclaimer.show();
                      $poSocial.show();
                    }
                  });
                  $newCloseButton.appendTo($cbox);
                  $('.email_popover--disclaimer').jScrollPane();
                }
              });

              return false;
            });

          // Inject .with-second-language into colorbox wrapper
          $('.show_second_language').each(function () {
            $(this).parents('.welcome15-overlay').addClass('with-second-language');
          });
          $('.js-input-pc-sms', context)
            .once()
            .focus(function (e) {
              $('.js-sms-terms-conditions', context).slideDown(0);
              $.colorbox.resize();
            });
        }
      });
    }
  };

  // --------
  // Callbacks and whatnot

  site.offers.welcome15 = {
    behavior: Drupal.behaviors.ELB_welcome15,

    getOverlayContent: function () {
      return $('#cboxLoadedContent');
    },

    /**
     * Initialize the signup form
     */
    initSignUpForm: function (context) {
      var self = site.offers.welcome15,
        overlay = self.getOverlayContent();
      var $emailSignupContent = $('.js-email-signup-welcome15', context);
      var $welcomeOverlay = $('.welcome15-overlay', context);

      if ($emailSignupContent.hasClass('email-signup-modified-layout')) {
        $welcomeOverlay.addClass('welcome15-content');
      }

      // we set visibility to 0 onOpen, to avoid "sliding" while the css is loading
      // now we will:
      // 1) fade them both out
      $('.welcome15-overlay #cboxContent,#cboxOverlay').fadeOut(1, function () {
        // 2) set visibility on and fade in the overelay
        $('#cboxOverlay')
          .css('visibility', 'visible')
          .fadeIn(500, function () {
            // 3) set visibility and fade in the content (very quickly)
            $('.welcome15-overlay #cboxContent').css('visibility', 'visible').fadeIn(100);
            if ($emailSignupContent.hasClass('email-signup-modified-layout')) {
              $('#cboxContent,#cboxLoadedContent', context).css('max-width', '500px');
              $welcomeOverlay.addClass('welcome15-content--active');
            }
          });
      });

      overlay.find('form').submit(function (e) {
        e.preventDefault();

        var $form = $(this);
        var $fields = $form.find('input');
        var paramObj = {};

        $.each($fields, function (index, field) {
          paramObj[field.name] = field.type === 'checkbox' && !field.checked ? '' : field.value;
        });

        generic.jsonrpc.fetch({
          method: 'rpc.form',
          params: [paramObj],
          onSuccess: function (jsonRpcResponse) {
            var response = jsonRpcResponse.getData(),
              userinfo = response.userinfo || {};
            var previously_opted_in_email_promotion = userinfo.previously_opted_in_email_promotion || 0;
            var b = self.behavior,
              t = b.templates,
              c = t.error, // Default template to return
              e = true, // Defaults to the error condition
              d = { message: "<p>There's been an error.</p>" }; // Error message

            // Figure out if the user has already signed up. If so, show 'em the already_signed_up template
            // c = (previously_opted_in_email_promotion == 0) ? t.thanks : t.already_signed_up;
            c = t.thanks;

            // And make sure there's no error to show
            e = false;

            // Redirect to Signin/Register Form
            if (welcome_overlay_loyalty) {
              site.track.loyaltyTag('enrollment overlay');
              var email = $('.js-input-pc-email').val();

              var url = '/account/signin.tmpl';

              if (!userinfo.registered_user) {
                url += '#registration';
              }
              $('<form/>', {
                action: url,
                method: 'POST'
              }).append(
                $('<input>', {
                  type: 'hidden',
                  name: 'email',
                  value: email
                })
              ).appendTo('body').submit();

              return;
            }

            // Remove any existing content, and show the error/thanks/already_signed_up template
            overlay.empty().append(e ? b.getTemplateContent(c, d) : b.getTemplateContent(c));
            $.colorbox.resize();

            // Act like we're opening a new colorbox
            $(document).trigger('cbox_complete');
          },
          onFailure: function (jsonRpcResponse) {
            var messages = jsonRpcResponse.getMessages();
            // Store messages and display them in error div element.
            var $errorMessages = $('.js-popup-offer-error-messages');
            var $emailInput = $('.js-input-pc-email');

            if (messages[0]) {
              $errorMessages
                .empty()
                .html('<div>' + messages[0].text + '</div>')
                .removeClass('hidden');
              $errorMessages.addClass('error');
            }
            $emailInput.addClass('error');
            $.colorbox.resize();
            generic.focusErrors($('.js-popup-offer-error-messages'), $('.js-email_input'));
          }
        });

        return false;
      });
    }
  };
})(jQuery);
